export default function ApollosGradient({ size = 10, className }) {
  return (
    <div
      className={`w-${size} aspect-square rounded-lg bg-gradient-to-br from-primary-500 to-primary-800 ${className}`}
    >
      <div className="p-[25%]">
        <svg
          width="100%"
          height="100%"
          preserveAspectRatio="none"
          viewBox="0 0 30 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 0.667 30.667 L 12.148 0 H 18.519 L 30 30.667 H 0.667 Z M 15.159 7.457 L 9.135 24.84 H 21.548 L 15.524 7.457 H 15.159 Z"
            fill="#ffffff"
          />
        </svg>
      </div>
    </div>
  );
}
